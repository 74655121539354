























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { AuthStore } from '~/store/auth'
import { login } from '~/api/auth'
import { UserStore } from '@admin/store/user'
import { prettifyApiError } from '~/services/prettify-api-error'

@Component
export default class LoginPage extends Vue {
	email = ''
	password = ''
	loginError = ''

	@AuthStore.Mutation('LOGIN') LOGIN: (token: string) => void
	@UserStore.Action('fetchAdminInfo') fetchAdminInfo: () => void

	validateFields() {
		this.login()
	}

	async login() {
		try {
			const { key } = await login({ email: this.email, password: this.password })
			this.LOGIN(key)
			this.fetchAdminInfo()
			Vue.router.push({ name: 'Dashboard' })
		} catch (err) {
			this.loginError = prettifyApiError(err)
		}
	}
}
